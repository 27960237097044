<template>
  <base-view
    icon="git-pull-request" title="Importación de pólizas del área de administración"
  >
    <!-- Se muestra el periodo fiscal -->
    <template v-slot:right-place>
      <div class="d-flex align-items-center justify-content-end">
        <div class="mx-2">
          <check-authorization :requiresAuthorizations="['importar poliza']">
            <b-button size="sm" variant="primary" @click="showModal = true">
              Importar pólizas
            </b-button>
          </check-authorization>
        </div>
        <x-periodo-fiscal-tag />
      </div>
    </template>

    <!-- Modal para mostrar el formulario de importación de pólizas -->
    <b-modal
      id="momentosContablesModal"
      v-model="showModal"
      centered
      hide-header-close
      no-close-on-esc
      no-close-on-backdrop
      hide-footer
    >
      <template #modal-title>Importar pólizas</template>
      <template #default="{cancel}">
        <polizas-administracion-form @on-sent="cancel()">
          <template #btn-cancel-place="{ clearForm }">
            <b-button
              variant="black"
              size="sm"
              @click="
                cancel()
                clearForm()
              "
            >
              Cancelar
            </b-button>
          </template>
        </polizas-administracion-form>
      </template>
    </b-modal>

    <!-- Muestra el resultado de la aplicación de las pólizas -->
    <polizas-administracion-response></polizas-administracion-response>

    <div class="container" v-show="!polizaHasResult">
      <div class="row">
        <div class="col col-12 d-flex justify-content-center">
          <i data-feather="archive" style="width: 7rem; height: 7rem"></i>
        </div>
        <div class="col col-12 d-flex justify-content-center">
          <h1 class="text-muted">Haz clic en importar pólizas.</h1>
        </div>
      </div>
    </div>

    <b-overlay :show="aplicarPolizasLoading">
      <template #overlay>
        <div class="text-center">
          <loading message="Aplicando pólizas, por favor espere..."></loading>
        </div>
      </template>

      <!-- Muestra el resultado de la importación -->
      <polizas-administracion-tabs v-if="polizaHasResult"></polizas-administracion-tabs>

      <div class="w-100 d-flex justify-content-between" v-if="polizaHasResult">
        <b-button @click="limpiarListado" variant="black" size="sm">
          Limpiar archivos
        </b-button>
        <b-button @click="onAplicarPolizas" variant="success" size="sm">
          Aplicar
        </b-button>
      </div>
    </b-overlay>
  </base-view>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import PolizasAdministracionForm from '@/components/Polizas/Administracion/PolizasAdministracionForm'
import PolizasAdministracionTabs from '@/components/Polizas/Administracion/PolizasAdministracionTabs'
import PolizasAdministracionResponse from '@/components/Polizas/Administracion/PolizasAdministracionResponse'

export default {
  name: 'PolizasAdministracionImportView',

  components: {
    PolizasAdministracionForm,
    PolizasAdministracionTabs,
    PolizasAdministracionResponse
  },

  data () {
    return {
      showModal: false,

      aplicarPolizasLoading: false
    }
  },

  computed: mapGetters('polizasAModule', ['polizaHasResult']),

  methods: {
    ...mapActions('polizasAModule', ['aplicarPolizas']),

    /**
     * Metodo que se ejecuta cuando el usuario ha decidido aplicar el resultado de
     * las previsualizaciones de las pólizas previamente subidas.
     */
    async onAplicarPolizas () {
      this.aplicarPolizasLoading = true
      const getval = await this.aplicarPolizas({ modulo: 'A' })
      this.aplicarPolizasLoading = false

      this.$notify(getval, 'Aplicación pólizas')

      if (!getval.error) this.limpiarListado()
    },

    limpiarListado () {
      this.$store.commit('polizasAModule/resetImportPoliza')
    }
  }
}
</script>
